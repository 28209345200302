<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title >
        <span class="headline primary--text">a</span>
        <v-spacer></v-spacer>
      </v-card-title>

      <v-data-table
        v-bind:headers="headers"
        v-bind:items="items"          
        v-model="selected"        
        selected-key="id"
        show-select
        :footer-props="{itemsPerPageOptions:[5,10,25,50,100]}"
        :options.sync="options"
        :server-items-length="total"
        :loading="loading"          
        >
        <tr slot="item.situacao" slot-scope="props">
          <v-chip label small :color="`${ props.item.situacao ? 'green' : 'red'} lighten-4 black--text`">
          {{props.item.situacao ? 'Ativo' : 'Inativo'}}
          </v-chip>
        </tr>        
      </v-data-table>      

      <alerta></alerta>       
      
    </v-card>
  </v-container>  
</template>

<script>
  import Alerta from '@/components/comum/Alerta.vue'
  import { mapActions /*, mapGetters*/ } from 'vuex'

  export default {
    name: 'telelaudo-list',
    data() {
      return {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['data'],
          sortDesc: [true]
        },
        loading: false,
        tipoAlerta: '',
        items: [],
        total: null,
              selected: [],
        selectAll: '',
        headers: [
          {
            text: 'Data',
            align: 'left',
            sortable: true,
            value: 'data'
          },
        ]    
      }
    },
    components: {
      Alerta
    },
    methods: {
      ...mapActions([
        'setAlerta',
      ]),
      atualizarLista () {
        var self = this
        this.loading = true
        const { page, itemsPerPage, sortBy, sortDesc } = this.options

        var offset = page - 1
        let order = sortDesc[0] ? 'desc' : 'asc'
        let sort = sortBy.length > 0 ? sortBy[0] : 'data '

        this.$http.get(`/exame?sort=${sort}&order=${order}&offset=${offset}&max=${itemsPerPage}`)
          .then(response => {            
            this.items = response.data.content
            this.total = response.data.totalSize
            this.options.itemsPerPage = response.data.size
            this.loading = false
          })
          .catch(() => {
            self.setAlerta( {
              tipoAlerta : 'error',
              textAlerta : 'Erro ao buscar exames.'
            })          

          })      
      },      
    },
    created() {
      this.atualizarLista()
    },
  }
</script>
